@import url("~node_modules/bootstrap/dist/css/bootstrap.min.css");
@import url("~node_modules/bootstrap-icons/font/bootstrap-icons.css");
@import url("~node_modules/@sweetalert2/themes/dark/dark.min.css");

[data-bs-theme="light"] {
  --message-left-bg: #5d5d5d;
  --message-right-bg: #006dcf;
  --text-color: #dddddd;
  --icon-color: #d5d5d5;
}

[data-bs-theme="dark"] {
  --message-left-bg: #3d3d3d;
  --message-right-bg: #004b8e;
  --text-color: #dee2e6;
  --icon-color: #cbd2d3;
}

::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #838383;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }
}

app-home {
  display: flex;
}

app-message {
  display: flex;
  flex-direction: column;
}

app-home, app-chat, app-info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

app-home app-chat, app-personalize {
  position: relative;
  flex: 1;
}

@media screen and (max-width: 768px) {
  app-home {
    display: unset;
  }

  app-home app-chat:has(.navbar.d-none) {
    position: absolute;
  }
}

textarea {
  resize: none;
}

input.error {
  border: 1px solid red;
}

span {
  &.warning {
    color: #955520;
  }

  &.error {
    color: #952020;
  }
}

[data-bs-toggle="dropdown"] {
  display: unset !important;
}

button:not([class*="btn-"]):not(#sidebar-open) {
  border: unset;
  transition: 0.2s;

  &.in-input {
    margin-left: -40px;
  }

  &.hidden {
    display: none;
  }

  &:active {
    border: unset;
  }
}

form.disabled {
  *:not(button) {
    pointer-events: none;
    color: #6c757d;
  }

  ::placeholder {
    color: #6c757d;
    opacity: 1; /* Firefox */
  }
}

#qr-code canvas, #qr-code video {
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 0.6);
}

#qr-code .camera-container {
  top:50%;
  left:50%;
  translate: -50% -50%;

  height: 80%;
  width: 90%;
}

#file-preview {
  .file {
    position: relative;
    display: inline-block;
  }

  .bi-x {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 25px;
    width: 25px;

    background-color: var(--bs-body-bg);

    border-radius: 15px;
    border: 1px solid #a5a5a5;

    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 0.6);
    cursor: pointer;

    &:before {
      position: absolute;
      top: 4px;
      left: 4px;
    }
  }

  img, video {
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 0.6);

    border-radius: 10px;

    margin: 15px;
    max-height: 65px;

    &.icon {
      padding: 5px;
    }
  }
}

.navbar {
  background-color: var(--bs-body-bg);
  box-shadow: 0 5px 6px -4px rgba(0, 0, 0, 0.2);
  align-items: center;

  .container-fluid {
    min-height: 44px;
  }

  .navbar-toggler {
    line-height: 30px;
    margin-bottom: 5px;
  }
}

.dropdown-menu {
  z-index: 3;
  top: 60px !important;
}

.dropdown-item {
  cursor: pointer;
}

.float-end {
  margin-bottom: 5px;
}

.h-table {
  min-height: 480px;
  height: 480px;
}

.spinner {
  text-align: center;
  line-height: 200px;
}

.divider {
  display: block;
  border-bottom: 1px solid #686868;

  margin: 10px 0;
}

.message .text, #messageInput {
  .reference {
    cursor: pointer;
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));

    font-size: 12px;
    margin: 15px 5px;
    display: inline-block;
  }
}

.swal2-top-end {
  top: 60px !important;
}

.bi-x-lg {
  cursor: pointer;
  padding: 5px 10px;

  &::before {
    font-weight: bold !important;
  }
}

.mt-6 {
  margin-top: 5rem !important;
}

// Twemoji
.text .emoji {
  width: 20px;
  margin: 0 0 5px 7px;
}

@media screen and (max-width: 768px) {
  .text .emoji {
    width: 18px;
    margin: 0 0 4px 5px;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    .navbar-collapse.show {
      padding-bottom: 10px;
    }

    .navbar-collapse {
      margin-bottom: -10px;
      padding-left: 15px;
      background-color: var(--bs-body-bg);
      box-shadow: rgba(0, 0, 0, 0.2) 0 5px 6px -4px;
    }

    .container-fluid {
      padding: 0;
    }

    .nav-item .dropdown-menu {
      border: none;
    }

    .navbar-brand {
      margin-left: 15px;
    }

    .navbar-toggler {
      margin-right: 15px;
    }
  }
}

// SweetAlert2
.swal-success-fix, .swal-success-circular-line-right, .swal-success-circular-line-left {
  background-color: #19191a !important;
}

.swal2-popup.swal2-toast {
  background: #19191a !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, .075), 0 1px 2px rgba(0, 0, 0, .075), 1px 2px 4px rgba(0, 0, 0, .075), 1px 3px 8px rgba(0, 0, 0, .075), 2px 4px 16px rgba(0, 0, 0, .075) !important;
}

.swal2-html-container, .swal2-select {
  color: #c2c2c2 !important;
}

.swal2-select {
  border: 1px solid #4d4d4d;
  border-radius: 5px;

  &:focus-visible {
    outline: none;
  }
}

.swal2-select option {
  background-color: #19191a !important;
}

.disable-scroll:not(sup) {
  overflow: hidden !important;
}

.cursor-pointer {
  cursor: pointer;
}

.date-divider {
  margin-top: 15px;
}

.system-message {
  margin-top: 5px;
}

.date-divider, .system-message {
  text-align: center;

  margin-bottom: 5px;

  div {
    display: inline-block;
    padding: 10px;
    background-color: #00000026;
    border-radius: 10px;
  }

  &:first-of-type div {
    margin-top: 0;
  }
}

.h-100-56-px {
  height: calc(100% - 56px);
}

.w-65-px {
  width: 65px;
}

.fs-7 {
  font-size: 1.35rem;
}

.settings-sidebar .fileViewer {
  top: 0 !important;
  height: 100%;
}

.picture {
  max-height: 175px;

  img {
    border-radius: 100%;

    margin: auto;
    max-width: 175px;
  }
}

.chat-picture .picture {
  min-width: 175px;
}

app-profile-picture {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 768px) {
  .picture {
    max-height: 150px;

    img {
      max-width: 150px;
    }
  }
}
